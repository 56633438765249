import { BASE_URL, TIME_OUT } from './request/config'
import XYRequest from '@/server/request'
import { getStorage } from '../utils/sessionStorage'

const xyRequest = new XYRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      config.headers['Authorization'] = window.location.hostname
      // config.headers['Authorization'] = 'blockadmin.ethvip.in'
      if (config.url !== '/api/B8bAcq/login') {
        if (config.method === 'post') {
          config.data['token'] = getStorage('token')
        } else if (config.method === 'get') {
          config.params['token'] = getStorage('token')
        }
      }
      return config
    },
    responseInterceptor: (res) => {
      return res
    }
  }
})

export default xyRequest
