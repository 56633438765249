import axios from 'axios'
import { Notification, Loading } from 'element-ui'
import router from '../../router'
import store from '../../store'

var initShowLoading = true
const DEFAULT_LOADING = true

class XYRequest {
  constructor(config) {
    this.instance = axios.create(config)
    this.interceptors = config.interceptors
    this.showLoading = config.showLoading ?? DEFAULT_LOADING
    initShowLoading = config.showLoading ?? DEFAULT_LOADING
    this.addInstanceInterceptors()
    this.addBaseInterceptors()
  }
  addInstanceInterceptors() {
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    )
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    )
  }
  addBaseInterceptors() {
    this.instance.interceptors.request.use(
      (config) => {
        // console.log('所有的实例都有的拦截器：请求成功拦截')
        if (config.showLoading) {
          this.loading = Loading.service({
            lock: true,
            text: '正在请求....',
            background: 'rgba(0,0,0,0.5)'
          })
        }
        return config
      },
      (err) => {
        return err
      }
    )
    this.instance.interceptors.response.use(
      (res) => {
        // console.log('所有的实例都有的拦截器：响应成功拦截')
        // 将loading移除
        this.loading?.close()
        return res.data
      },
      (err) => {
        // 将loading移除
        this.loading?.close()
        return err
      }
    )
  }

  request(config) {
    return new Promise((resolve) => {
      // 判断是否显示loading
      if (config.showLoading === false) {
        this.showLoading = config.showLoading
      }
      this.instance
        .request(config)
        .then((res) => {
          // 将showLoading设置true,这样不会影响下一个请求
          this.showLoading = initShowLoading
          if (res.code === '200') {
            resolve(res)
          } else if (res.code === '10000007') {
            Notification({
              title: '错误',
              message: res.msg,
              type: 'error'
            })
            store.dispatch('initActive')
            router.replace({
              name: 'teacherlogin'
            })
          } else {
            Notification({
              title: '错误',
              message: res.msg,
              type: 'error'
            })
          }
        })
        .catch(() => {
          this.showLoading = initShowLoading
        })
    })
  }

  get(config) {
    return this.request({ ...config, method: 'GET' })
  }

  post(config) {
    return this.request({ ...config, method: 'POST' })
  }

  delete(config) {
    return this.request({ ...config, method: 'DELETE' })
  }

  put(config) {
    return this.request({ ...config, method: 'PUT' })
  }
}

export default XYRequest
