import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './elementui/index'
import './assets/scss/reset.scss'
import xyRequest from './server'

Vue.prototype.$store = store
Vue.prototype.$request = xyRequest
Vue.config.productionTip = false
Vue.prototype.$baseUrl = 'https://hn.xgk.net.cn'
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
