import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    currentActiveKey: 1,
    activeNames: ['首页'],
    activeTags: [
      {
        label: '首页',
        routerName: 'welcome',
        index: '1'
      }
    ],
    currentActiveTag: {
      label: '首页',
      routerName: 'welcome',
      index: '1'
    },
    activeMenuIndexs: '1',
    allMenus: [],
    allMenuKeys: [],
    userType: 0,
    userId: 0
  },
  getters: {},
  mutations: {
    setToken(state, value) {
      state.token = value
    },
    setActiveNames(state, value) {
      state.activeNames = value
    },
    setCurrentActiveKey(state, value) {
      state.currentActiveKey = value
    },
    setActiveTags(state, value) {
      state.activeTags = value
    },
    setCurrentActiveTag(state, value) {
      state.currentActiveTag = value
    },
    setActiveMenuIndexs(state, value) {
      state.activeMenuIndexs = value
    },
    initActive(state) {
      state.activeNames = ['首页']
      state.activeMenuIndexs = '1'
      state.activeTags = [
        {
          label: '首页',
          routerName: 'welcome',
          index: '1'
        }
      ]
      state.currentActiveTag = {
        label: '首页',
        routerName: 'welcome',
        index: '1'
      }
    },
    setAllMenus(state, value) {
      state.allMenus = value
    },
    setAllMenuKeys(state, value) {
      state.allMenuKeys = value
    },
    setUserType(state, value) {
      state.userType = value
    },
    setUserId(state, value) {
      state.userId = value
    }
  },
  actions: {
    setToken(context, value) {
      context.commit('setToken', value)
    },
    setActiveNames(context, value) {
      context.commit('setActiveNames', value)
    },
    setCurrentActiveKey(context, value) {
      context.commit('setCurrentActiveKey', value)
    },
    setActiveTags(context, value) {
      context.commit('setActiveTags', value)
    },
    setCurrentActiveTag(context, value) {
      context.commit('setCurrentActiveTag', value)
    },
    setActiveMenuIndexs(context, value) {
      context.commit('setActiveMenuIndexs', value)
    },
    initActive(context) {
      context.commit('initActive')
    },
    setAllMenus(context, value) {
      context.commit('setAllMenus', value)
    },
    setAllMenuKeys(context, value) {
      context.commit('setAllMenuKeys', value)
    },
    setUserType({ commit }, value) {
      commit('setUserType', value)
    },
    setUserId({ commit }, value) {
      commit('setUserId', value)
    }
  },
  modules: {}
})
