import Vue from 'vue'
import VueRouter from 'vue-router'
import { getStorage } from '@/utils/sessionStorage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/teacher',
    name: 'teacherlogin',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/admin',
    name: 'adminlogin',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/',
    redirect: '/welcome',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    children: [
      {
        path: '/welcome',
        name: 'welcome',
        component: () =>
          import(/* webpackChunkName: "welcome" */ '../views/Welcome.vue')
      },
      {
        path: '/adminer',
        name: 'admin',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
      },
      {
        path: '/role',
        name: 'role',
        component: () =>
          import(/* webpackChunkName: "role" */ '../views/Roles.vue')
      },
      {
        path: '/operation_log',
        name: 'operation_log',
        component: () =>
          import(/* webpackChunkName: "sys" */ '../views/OperationLog.vue')
      },
      {
        path: '/banner',
        name: 'banner',
        component: () =>
          import(/* webpackChunkName: "sys" */ '../views/Banner.vue')
      },
      {
        path: '/organization',
        name: 'organization',
        component: () =>
          import(/* webpackChunkName: "sys" */ '../views/NewOrganization.vue')
      },
      {
        path: '/chat',
        name: 'chat',
        component: () =>
          import(/* webpackChunkName: "chat" */ '../views/Chat.vue')
      },
      {
        path: '/chat_content/:chatId',
        name: 'chat_content',
        component: () =>
          import(
            /* webpackChunkName: "chat_content" */ '../views/ChatContent.vue'
          )
      },
      {
        path: '/notice',
        name: 'notice',
        component: () =>
          import(/* webpackChunkName: "notice" */ '../views/Notice.vue')
      },
      {
        path: '/new_notice',
        name: 'new_notice',
        component: () =>
          import(/* webpackChunkName: "notice" */ '../views/NewNotice.vue')
      },
      {
        path: '/school',
        name: 'school',
        component: () =>
          import(/* webpackChunkName: "school" */ '../views/School.vue')
      },
      {
        path: '/approve',
        name: 'approve',
        component: () =>
          import(/* webpackChunkName: "school" */ '../views/Approve.vue')
      },
      {
        path: '/class_brand',
        name: 'class_brand',
        component: () =>
          import(/* webpackChunkName: "school" */ '../views/ClassBrand.vue')
      },
      {
        path: '/service',
        name: 'service',
        component: () =>
          import(/* webpackChunkName: "service" */ '../views/Service.vue')
      },
      {
        path: '/setting',
        name: 'setting',
        component: () =>
          import(/* webpackChunkName: "sys" */ '../views/Setting.vue')
      },
      {
        path: '/activity',
        name: 'activity',
        component: () =>
          import(/* webpackChunkName: "sys" */ '../views/Activity.vue')
      },
      {
        path: '/other_activity',
        name: 'other_activity',
        component: () =>
          import(
            /* webpackChunkName: "sys" */ '../views/other/OtherActivity.vue'
          )
      },
      {
        path: '/activity_category',
        name: 'activity_category',
        component: () =>
          import(
            /* webpackChunkName: "sys" */ '../views/other/ActivityCategory.vue'
          )
      },
      {
        path: '/official_account',
        name: 'official_account',
        component: () =>
          import(
            /* webpackChunkName: "sys" */ '../views/other/OfficialAccount.vue'
          )
      },
      {
        path: '/school_activity_notice',
        name: 'school_activity_notice',
        component: () =>
          import(
            /* webpackChunkName: "sys" */ '../views/notice/SchoolActivityNotice.vue'
          )
      },
      {
        path: '/init_data',
        name: 'init_data',
        component: () =>
          import(/* webpackChunkName: "sys" */ '../views/InitData.vue')
      },
      {
        path: '/all_peoples',
        name: 'all_peoples',
        component: () =>
          import(/* webpackChunkName: "sys" */ '../views/AllStuTecParents.vue')
      },
      {
        path: '/certificate',
        name: 'certificate',
        component: () =>
          import(/* webpackChunkName: "sys" */ '../views/other/Certificate.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (
    to.name === 'teacherlogin' ||
    to.name == 'adminlogin' ||
    to.name === 'certificate'
  ) {
    next()
  } else {
    if (getStorage('token')) {
      next()
    } else {
      next('teacher')
    }
  }
})

export default router
