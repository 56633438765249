export function setStorage(key, value) {
  window.sessionStorage.setItem(key, value)
}

export function getStorage(key) {
  return window.sessionStorage.getItem(key)
}

export function removeStorage(key) {
  if (window.sessionStorage.getItem(key)) {
    window.sessionStorage.removeItem(key)
  }
}

export function clearStorage() {
  window.sessionStorage.clear()
}
